//COMPONENTS
import { PAGE_TYPE } from '@/ts/constant'
import Layout from '../layouts/default'
import {
  Collection,
  Get_Page_With_FilterQuery,
  Pages,
  Pages_Sections,
} from '@/ts/graphqlschema'
import { DOMAIN } from '@/data/metadata'
import Head from 'next/head'
import { formatPagesMetaData } from '../utils'
import { GetStaticProps } from 'next'
import { clientSecret } from '@/graphql/apollo-client-secret'
import { GET_PAGE_WITH_FILTER } from '@/graphql/queries/page/get_page_by_slug'
import fetchDataForHTMLBuilder from '@/utils/fetch-html-builder-data'
import GrapeParser from '@/components/GrapeParser'
import React from 'react'

type HomePageProps = {
  pageData: Pages
  css?: string
  component?: any
  globalData?: any
  extra?: any
  collections: Collection[]
  section_list_products: Array<Pages_Sections>
}

export const getStaticProps: GetStaticProps = async () => {
  const queryParams = {
    page_filter: {
      page_type: {
        _eq: PAGE_TYPE.HomePage,
      },
      // language: {
      //   _eq: locale,
      // }
    },
  }
  try {
    const { data } = await clientSecret.query<Get_Page_With_FilterQuery>({
      query: GET_PAGE_WITH_FILTER,
      variables: queryParams,
      fetchPolicy: 'network-only',
    })
    const pageData = data.pages[0]

    const content = JSON.parse(pageData?.content)
    const css = content?.css
    const component = content?.projectData?.pages?.[0]?.frames?.[0]?.component

    const globalData = {}
    await fetchDataForHTMLBuilder(component, globalData)

    return {
      props: {
        pageData,
        css: css || null,
        component: component || null,
        globalData: globalData || null,
      },
      revalidate: 15,
    }
  } catch {}

  return {
    redirect: {
      destination: '/404',
      permanent: false,
    },
    revalidate: 15,
  }
}

const HomePage: (props: HomePageProps) => JSX.Element = (
  props: HomePageProps
) => {
  const { css, globalData, component, pageData } = props
  const canonicalUrl = `${DOMAIN}`

  //above & below content
  const above_content =
    pageData?.above_content && JSON.parse(pageData?.above_content?.content)
  const below_content =
    pageData?.below_content && JSON.parse(pageData?.below_content?.content)
  const above_content_css = above_content?.css
  const below_content_css = below_content?.css
  const above_content_component =
    above_content?.projectData?.pages?.[0]?.frames?.[0]?.component
  const below_content_component =
    below_content?.projectData?.pages?.[0]?.frames?.[0]?.component

  return (
    <Layout
      shouldRenderHighlight
      shouldRenderContacts
      metadata={formatPagesMetaData({ ...pageData, canonicalUrl })}
    >
      {/*<FixedHeader />*/}
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>

      {/*Above Content*/}
      <div>
        {above_content_component && (
          <GrapeParser component={above_content_component} globalData={{}} />
        )}
        {above_content_css && <style jsx>{above_content_css}</style>}
      </div>

      <div>
        {component && (
          <GrapeParser
            component={component}
            currentItem={pageData}
            globalData={globalData}
          />
        )}
        {css && <style jsx>{css}</style>}
      </div>

      {/*Below Content*/}
      <div>
        {below_content_component && (
          <GrapeParser component={below_content_component} globalData={{}} />
        )}
        {below_content_css && <style jsx>{below_content_css}</style>}
      </div>
    </Layout>
  )
}

export default HomePage
