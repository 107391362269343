import {
  Collection,
  Pages,
  Post,
  Post_Keywords,
  Products,
  Product_Categories,
  Product_Tags,
  Tag,
} from '@/ts/graphqlschema'
import { IMetadata } from '@/ts/models'
import { getCMSImageUrl } from '../image-utils/get-cms-image'

const returnStringDefault = (data: any) => data || ''

export const formatPagesMetaData = (
  data:
    | Omit<Pages, 'id'> & {
        canonicalUrl: string
      }
): IMetadata => {
  const { description, thumbnail, name, canonicalUrl, keywords } = data
  const seo_keywords = keywords?.map(item => item?.keywords_id?.name)
  return {
    title: returnStringDefault(name),
    description: returnStringDefault(description),
    twitterDescription: returnStringDefault(description),
    // Open Graph Metadata
    ogType: 'website',
    ogTitle: returnStringDefault(name),
    ogDescription: returnStringDefault(description),
    ogImage: getCMSImageUrl(thumbnail || undefined, 'md', 'jpeg'),
    ogUrl: returnStringDefault(canonicalUrl),
    // Twitter Card Metadata
    twitterUrl: returnStringDefault(canonicalUrl),
    twitterTitle: returnStringDefault(name),
    twitterImageSrc: getCMSImageUrl(thumbnail || undefined, 'md', 'jpeg'),
    imageWidth: `${thumbnail?.width}`,
    imageHeight: `${thumbnail?.height}`,
    imageAlt: thumbnail?.description,
    keywords: seo_keywords,
  }
}
export const formatPostMetaData = (
  data:
    | (Post & {
        canonicalUrl: string
      })
    | (Products & {
        canonicalUrl: string
        title?: string
        keywords?: Post_Keywords[]
      })
    | (Tag & {
        canonicalUrl: string
        title?: string
        keywords?: Post_Keywords[]
      })
    | (Collection & {
        title: string
        canonicalUrl: string
        keywords?: Post_Keywords[]
      })
    | (Product_Tags & {
        title: string
        canonicalUrl: string
        keywords?: Post_Keywords[]
      })
    | (Product_Categories & {
        title: string
        canonicalUrl: string
        keywords?: Post_Keywords[]
      })
): IMetadata => {
  const { description, thumbnail, title, canonicalUrl, keywords } = data
  const seo_keywords = keywords?.map(item => item?.keywords_id?.name)
  return {
    title: returnStringDefault(title),
    description: returnStringDefault(description),
    twitterDescription: returnStringDefault(description),
    // Open Graph Metadata
    ogType: 'website',
    ogTitle: returnStringDefault(title),
    ogDescription: returnStringDefault(description),
    ogImage: getCMSImageUrl(thumbnail || undefined, undefined, 'jpeg'),
    ogUrl: returnStringDefault(canonicalUrl),
    // Twitter Card Metadata
    twitterUrl: returnStringDefault(canonicalUrl),
    twitterTitle: returnStringDefault(title),
    twitterImageSrc: getCMSImageUrl(thumbnail || undefined, undefined, 'jpeg'),
    imageWidth: `${thumbnail?.width}`,
    imageHeight: `${thumbnail?.height}`,
    imageAlt: thumbnail?.description,
    keywords: seo_keywords,
  }
}
